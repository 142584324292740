
.logo__container {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    padding-top: 2.5vh;

    /* Animations */
    transition-timing-function: ease-in;
    transition: all 0.75s;
}

.logo__animated-container {
    padding-top: 0px;
}

.logo__logoImage {
    width: 6.4rem;
    height: 6.4rem;

    @include phone {
        width: 5.5rem;
        height: 5.5rem;
    }

    @include littlePhone {
        width: 4.5rem;
        height: 4.5rem;
    }
}

.logo__logoName {
    color: black;
    font-family: $main_font;
    font-size: 3.4rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 3.4px;
    text-transform: uppercase;
    margin-left: 20px;


    @include phone {
        font-size: 2.5rem;
    }

    @include littlePhone {
        font-size: 2rem;
    }
}

.logo__logoName > span {
    color: black;
    font-family: $main_font;
    font-size: 3.2rem;
    font-style: normal;
    font-weight: lighter;
    line-height: normal;
    letter-spacing: 3.2px;

    @include phone {
        font-size: 2.4rem;
    }

    @include littlePhone {
        font-size: 1.9rem;
    }
}

// Logo Footer


.logoFooter__container {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

.logoFooter__logoImage {
    width: 5rem;
    height: 5rem;

    @include phone {
        width: 5rem;
        height: 5rem;
    }
}

.logoFooter__logoName {
    color: black;
    font-family: $main_font;
    font-size: 3rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 3.4px;
    text-transform: uppercase;
    margin-left: 20px;


    @include phone {
        font-size: 3rem;
    }
}

.logoFooter__logoName > span {
    color: black;
    font-family: $main_font;
    font-size: 2.8rem;
    font-style: normal;
    font-weight: lighter;
    line-height: normal;
    letter-spacing: 3.2px;

    @include phone {
        font-size: 2.8rem;
    }
}