
.social__container {
    width: 100%;
    background: #F8F9FA;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 150px;
    padding-bottom: 150px;
    position: relative;
}

.social__id-target {
    height: 0px;
    width: 0px;
    position: absolute;
    top: -25vh;
    left: 0px;

}

.social__content-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;



}

.social__content-container > h1 {
    color: #000;
    font-size: 68px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 6.8px;
    margin-bottom: 15px;

    @include tablet {
        font-size: 60px;
    }

    @include phone {
        font-size: 40px;
    }
}

.social__content-container > h2 {
    color: #000;
    font-size: 11px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 1.1px;
    text-transform: uppercase;

    @include phone {
        font-size: 10px;
    }
}


.social__buttons-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 55px;

    @include littlePhone {
        flex-direction: column;
    }
}

.social__button {
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 15px;
    padding-right: 15px;

    &:nth-child(2) {
        border-left: solid 1px #000;
        border-right: solid 1px #000;

        @include littlePhone {
            border-left: none;
            border-right: none;
            border-top: solid 1px #d9d9d9;
            border-bottom: solid 1px #d9d9d9;
            margin-top: 20px;
            margin-bottom: 20px;
            padding-top: 20px;
            padding-bottom: 20px;
        }
    }


    @include phone {
        width: 85px;
        padding-left: 20px;
        padding-right: 20px;
    }
}

.social__button > img {
    height: 16px;
    width: 16px;
}

.social__button > h3 {
    color: #000;
    font-size: 13px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 3.9px;
    text-transform: uppercase;
    margin-left: 10px;

    @include phone {
        font-size: 12px;
        letter-spacing: 1px;
        margin-left: 5px;
    }

    @include littlePhone {
        margin-left: 10px;
    }
}