
.introduction__container {
    width: 100%;
    height: 100vh;
}

.introduction__header-null-space-container {
    width: 100%;
    height: 20vh;
    background-color: white;
}

.introduction__content-container {
    width: 100%;
    height: 80vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
    margin-top: 5vh;
}

.introduction__info {
    width: 35%;
    height: 75vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    letter-spacing: 4.8px;
    z-index: 2;
    color: #000;

    @include notebook {
        width: 40%;
    }

    @include tablet {
        color: #fff;
        text-align: center;
    }

    & > div {
        margin-left: 50px;

        @include tablet {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-left: 0px;
        }
    }

    & > div  > h1 {
        font-size: 4rem;
        font-weight: 400;

        @include phone {
            font-size: 2rem;
        }
    }

    & > div > h1 > span {
        font-size: 6rem;
        font-weight: 600;

        @include phone {
            font-size: 4rem;
        }
    }

    & > div > h2 {
        font-size: 1.5rem;
        line-height: 50px; /* 250% */
        letter-spacing: 2px;
        font-weight: 200;
        margin-top: 30px;

        @include phone {
            font-size: 1rem;
        }
    }

    & > div > h2 > span {
        font-size: 1.5rem;
        line-height: 50px; /* 250% */
        letter-spacing: 2px;
        font-weight: 400;

        @include phone {
            font-size: 1rem;
        }
    }

    & > div > a {
        height: 50px;
        width: 250px;
        border-radius: 4px;
        background: #0F6630;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
        transition: all 0.2s;
        
        @include phone {
            height: 40px;
            width: 180px;
        }

        &:hover {
            background: #25D366;
        }
    }

    & > div > a > img {
        height: 2.8rem;
        width: 2.8rem;
        margin-right: 15px;

        @include phone {
            height: 1.8rem;
            width: 1.8rem;
            margin-right: 10px;
        }
    }

    & > div > a > h3 {
        color: #FFF;
        font-size: 2.2rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 2.2px;
        text-transform: uppercase;

        @include phone {
            font-size: 1.4rem;
        }
    }

    @include tablet {
        position: absolute;
        width: 100%;
    }

}



.introduction__image {
    width: 65%;
    height: 75vh;
    background: url('https://cdn.srandamios.com.ar/andamios-intro.webp') center no-repeat;
    background-size: cover;
    z-index: 1;
    position: relative;

    @include notebook {
        width: 60%;
    }

    @include tablet {
        width: 100%;
    }

}

.introduction__shadow-first {
    width: 200px;
    height: 120%;
    position: absolute;
    left: -200px;
    bottom: 0;
    background-color: white;
    z-index: 1;    
    box-shadow: 80px 0px 50px 0px #FFF;

    @include tablet {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.8);
        z-index: 1;    
        box-shadow: none;
    }
}

.introduction__shadow-second {
    width: 150%;
    height: 200px;
    position: absolute;
    left: -50%;
    bottom: -200px;
    background-color: white;
    z-index: 1;    
    box-shadow: 50px -75px 50px 0px #FFF;
}




