
.header__container {
    width: 100%;
    height: 25vh;
    position: fixed;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 3;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
    background-color: white;

    /* Animations */
    transition-timing-function: ease-in;
    transition: all 0.75s;
}

.header__animated-container {
    height: 20vh;
}