* {
    font-family: $main_font;
    margin: 0px;
    text-decoration: none;
}

html, body {
    max-height: 100%;
    max-width: 100%;
    height: auto;
    width: auto;
    font-size: 62.5%;
    overflow-x: hidden;
}

button {
    border: none;
    background-color: transparent;
}

main {
    width: 100%;
}

.mt-1 {
    margin-top: 5px;
}

.mt-2 {
    margin-top: 10px;
}

.mt-5 {
    margin-top: 20px;
}

.mb-1 {
    margin-bottom: 5px;
}

.mb-2 {
    margin-bottom: 10px;
}

.mb-5 {
    margin-bottom: 20px;
}

.fs-1 {
    font-size: 10px;
}

.fs-2 {
    font-size: 12px;
}

.fs-3 {
    font-size: 14px;
}

.fs-4 {
    font-size: 16px;
}

.fs-5 {
    font-size: 18px;
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-default {
    cursor: default;
}

.bold {
    font-weight: bold;
}

.uppercase {
    text-transform: uppercase;
}