
.tips__container {
    width: 100%;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;

    @include phone {
        margin-top: -25px;
    }
}

.tips__content-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    

    @include phone {
        flex-direction: column;
    }
}

.tips__info {
    width: 50rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    z-index: 2;
    color: #000;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 20px;

    &:nth-child(2) {
        border-left: solid 1px #000;
        border-right: solid 1px #000;

        @include phone {
            border-left: none;
            border-right: none;
            border-top: solid 1px #d9d9d9;
            border-bottom: solid 1px #d9d9d9;
        }
    }
    
    & > img {
        width: 2.8rem;
        height: 2.8rem;
        z-index: 1;
        margin-right: 15px;
    }

    @include phone {
        width: auto;
        padding-top: 40px;
        padding-bottom: 40px;
        padding-left: 10px;
        padding-right: 10px;
        max-width: 370px;
    }
}


.tips__info > div {
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    & > h1 {
        color: #000;
        font-size: inherit;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
        text-align: center;
        letter-spacing: 4px;

        @include tablet {
            font-size: 12px;
        }

    }

    & > h2 {
        width: 85%;
        color: #000;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: none;
        margin-top: 5px;
        

        @include tablet {
            font-size: 12px;
        }


    }

    @include phone {
        max-width: 315px;
    }

}