
.navbar__container {
    margin-top: 25vh * 0.2;

    /* Animations */
    transition-timing-function: ease-in;
    transition: all 0.75s;
}

.navbar__animated-container {
    margin-top: 20vh * 0.15;
}

.navbar__buttons {
    width: 450px;
    display: flex;
    color: black;
    align-items: center;
    justify-content: space-between;

    @include phone {
        width: auto;
    }
}

.navbar__buttons > button {
    color: black;
    text-transform: uppercase;
    font-weight: normal;
    font-size: 1.2rem;
    letter-spacing: 1.4px;
    padding: 10px;
    border-top: solid 1px transparent;
    border-bottom: solid 1px transparent;
    cursor: pointer;
    
    &:hover {
        border-color: #000;
    }

    @include phone {
        font-size: 1rem;
    }

    @include littlePhone {
        padding-left: 5px;
        padding-right: 5px;
    }

}