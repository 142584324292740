
.quotator__container {
    width: 100%;
    min-height: 80vh;
    background: white;
    background-size: cover;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-top: 10vh;
    margin-bottom: 10vh;
}

.quotator__id-target {
    height: 0px;
    width: 0px;
    position: absolute;
    top: -20vh;
    left: 0px;

    @include tablet {
        top: -15vh;
}
  
    @include phone {
      top: -21vh;
    }
}


.quotator__content-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.quotator__content-container > h1 {
    width: 90%;
    color: #000;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 3.2px;
    text-transform: uppercase;
    margin-bottom: 20px;
    
    & > span {
        color: #000;
        font-size: inherit;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 3.2px;
        text-transform: uppercase;
    } 

    @include tablet {
        font-size: 28px;
    }

    @include phone {
        font-size: 20px;
    }
}

.quotator__content-container > h2 {
    width: 90%;
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: none;
    margin-bottom: 20px;

    @include tablet {
        font-size: 14px;
    }

    @include phone {
        font-size: 14px;
    }
}



.quotator__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
}

.quotator__input-container {
    height: 40px;
    width: 730px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    border: 1px solid #bdbdbd;
    background: #FFF;
    position: relative;
    margin-bottom: 50px;

    @include tablet {
        width: 640px;
    }

    @include phone {
        width: 300px;
    }

}

.quotator__custom-input-container {
    height: auto;
    width: 730px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;

    & > div:nth-child(2) {
        margin-left: 10px;

        @include phone {
            margin-left: 0px;
        }
    }

    @include tablet {
        width: 640px;
    }

    @include phone {
        width: 300px;
        flex-direction: column;
    }
}

.quotator__input-container > h2 {
    color: #4F5457;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
    position: absolute;
    left: 5px;
    top: -22px;
}

.quotator__input-container > input {
    position: absolute;
    width: 700px;
    margin: auto;
    height: 40px;
    border-radius: 6px;
    padding-left: 15px;
    padding-right: 15px;
    background-color: white;
    border: solid 1px #bdbdbd !important;

    @include tablet {
        width: 610px;
    }

    @include phone {
        width: 270px;
    }

    &:disabled {
        background-color: #F8F9FA;
    }

    &:focus {
        border-bottom: 1px solid #257FA4;
        outline: none;
    }
}

.quotator__input-container > select {
    position: absolute;
    width: 100%;
    margin: auto;
    height: 40px;
    border-radius: 6px;
    padding-left: 15px;
    padding-right: 15px;
    background-color: white;
    border: none;

    &:disabled {
        background-color: #F8F9FA;
        color: #000000;
    }

    &:focus {
        outline: none;
    }
}

.quotator__input-custom {
    width: 330px !important;
    margin: auto;
    height: 40px;
    border-radius: 6px;
    padding-left: 15px;
    padding-right: 15px;
    background-color: white;
    border: solid 1px #bdbdbd !important;

    @include tablet {
        width: 285px !important;
    }

    @include phone {
        width: 270px !important;
    }

    &:disabled {
        background-color: #F8F9FA;
    }

    &:focus {
        border-bottom: 1px solid #257FA4;
        outline: none;
    }
}

.quotator__form > h3 {
    width: 730px;
    color: #4F5457;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: none;
    margin-bottom: 20px;
    margin-top: -10px;

    @include tablet {
        width: 640px;
        font-size: 14px;
    }

    @include phone {
        width: 300px;
    }
}

.quotator__form > button {
    width: 470px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background: #25D366;
    margin-top: 20px;
    transition: all 100ms ease-in;

    &:disabled {
        background-color: #0F6630;
    }

    &:hover {
        background-color: #0F6630;
    }

    & > img {
        width: 32px;
        height: 32px;
        margin-right: 15px;

        @include tablet {
            width: 28px;
            height: 28px;
        }

        @include phone {
            width: 20px;
            height: 20px;
            margin-right: 5px;
        }
    }

    & > h3 {
        color: #FFF;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 2.5px;
        text-transform: uppercase;

        @include tablet {
            font-size: 20px;
        }

        @include phone {
            font-size: 16px;
            letter-spacing: 0px;
        }
    }

    @include tablet {
        width: 420px;
        height: 50px;
    }

    @include phone {
        width: 280px;
        height: 40px;
    }
}