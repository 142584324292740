
.catalog__container {
    width: 100%;
    min-height: 80vh;
    background: #F8F9FA;
    background-size: cover;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 150px;

    padding-top: 10vh;
    padding-bottom: 10vh;

    @include phone {
        padding-top: 10vh;
        padding-bottom: 10vh;
    }
}

.catalog__id-target {
    height: 0px;
    width: 0px;
    position: absolute;
    top: -10vh;
    left: 0px;
  
    @include phone {
      top: -15vh;
    }
}

.catalog__content-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.catalog__content-container > h1 {
    width: 90%;
    color: #000;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 3.2px;
    text-transform: uppercase;
    margin-bottom: 40px;
    
    & > span {
        color: #000;
        font-size: inherit;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 3.2px;
        text-transform: uppercase;
    } 

    @include tablet {
        font-size: 28px;
    }

    @include phone {
        font-size: 20px;
    }
}

.catalog__list {
    display: grid;
    column-gap: $space;
    row-gap: 5rem;
    grid-template-columns: repeat(3, 1fr);

    @include tablet {
        column-gap: $space / 1.5;
    }

    @include phone {
        grid-template-columns: repeat(2, 1fr);
        column-gap: $space / 3;
    }
}

.catalog__card {
    height: 25rem;
    width: 17rem;
    border-radius: 8px;
    border: 1px solid #E6E6E6;
    background: #FFF;
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    /* Animations */
    transition-timing-function: ease-in;
    transition: all 0.25s;

    &:hover {
        transform: scale(1.05);
    }

    & > img {
        width: 140px;
        height: 140px;
        margin-bottom: 20px;

        @include tablet {
            width: 120px;
            height: 120px;
        }

        @include phone {
            width: 100px;
            height: 100px;
        }

    }

    & > h2 {
        color: #000;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 1.6px;
        text-transform: uppercase;
        margin-top: 50px;
        position: absolute;
        bottom: 15px;
        text-align: center;

        @include tablet {
            font-size: 14px;
        }
    
        @include phone {
            font-size: 12px;
        }
    }

    @include tablet {
        width: 15rem;
    }

    @include phone {
        height: 22.5rem;
        width: 12.5rem;
    }

}

