
.footer__container {
    width: 100%;
    background: #FFF;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 100px;
    padding-bottom: 100px;
}

.footer__content-container {
    width: 75%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;

    @include phone {
        width: 70%;
        flex-direction: column;
    }
}

.footer__buttons-container {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;

}

.footer__button {
    display: flex;
    align-items: center;
    justify-content: center;

    &:nth-child(1) {
        margin-bottom: 30px;
        padding-right: 50px;

        @include phone {
            padding-right: 0px;
        }
    }

    @include phone {
        margin-bottom: 50px;
    }
}

.footer__button > img {
    height: 24px;
    width: 24px;
}

.footer__button > h3 {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.4px;
    text-decoration-line: underline;
    margin-left: 15px;

    @include phone {
        font-size: 12px;
    }
}