
.counter__container {
    width: 100%;
    height: 40rem;
    background: url('https://cdn.srandamios.com.ar/andamios-counter.webp') center no-repeat;
    background-size: cover;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    position: relative;

    @include phone {
        margin-top: 70px;
        height: auto;
    }
}

.counter__content-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 2;

    @include phone {
        flex-direction: column;
    }
}

.counter__info {
    width: 50rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    letter-spacing: 4.8px;
    z-index: 2;
    color: #fff;
    padding-left: 20px;
    padding-right: 20px;

    @include phone {
        width: auto;
        padding-top: 40px;
        padding-bottom: 40px;
        padding-left: 0px;
        padding-right: 0px;
    }

    &:nth-child(2) {
        border-left: solid 1px #fff;
        border-right: solid 1px #fff;

        @include phone {
            border-left: none;
            border-right: none;
            border-top: solid 1px #d9d9d9;
            border-bottom: solid 1px #d9d9d9;
        }
    }
    
    & > h1 {
        color: #fff;
        font-size: 78px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: uppercase;
        text-align: center;
        letter-spacing: 7.8px;
        margin-bottom: 20px;

        @include tablet {
            font-size: 54px;
        }

    }

    & > h2 {
        color: #e6e6e6;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-top: 5px;
        text-align: center;
        letter-spacing: 1.6px;
        text-transform: uppercase;
        margin-top: 20px;

        @include tablet {
            font-size: 12px;
        }
    }
}

.counter__shadow {
    height: 100%;
    width: 100%;
    left: 0;
    bottom: 0;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 1;    
}

